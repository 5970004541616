import { FC, MouseEvent } from 'react';

import { COLORS } from '@theme';

interface WarningIconProps {
  fillColor?: string;
  size?: 'sm' | 'lg';

  onClick?(e?: MouseEvent<SVGSVGElement, MouseEvent>): void;
}

const WarningIcon: FC<WarningIconProps> = ({ fillColor = COLORS.PASTEL_RED, size = 'sm' }) => (
  <svg
    width={size === 'sm' ? '26' : '13'}
    height={size === 'sm' ? '42' : '21'}
    viewBox="0 0 26 42"
    fill="none"
  >
    <path
      d="M14.1367 13.263V18.697C14.1367 19.2733 14.1082 19.8402 14.0512 20.3975C13.9942 20.9485 13.9182 21.5343 13.8232 22.155H12.2367C12.1417 21.5343 12.0657 20.9485 12.0087 20.3975C11.9517 19.8402 11.9232 19.2733 11.9232 18.697V13.263H14.1367ZM11.5622 25.708C11.5622 25.5117 11.597 25.3248 11.6667 25.1475C11.7427 24.9702 11.844 24.8182 11.9707 24.6915C12.1037 24.5648 12.2588 24.4635 12.4362 24.3875C12.6135 24.3115 12.8035 24.2735 13.0062 24.2735C13.2025 24.2735 13.3893 24.3115 13.5667 24.3875C13.744 24.4635 13.896 24.5648 14.0227 24.6915C14.1493 24.8182 14.2507 24.9702 14.3267 25.1475C14.4027 25.3248 14.4407 25.5117 14.4407 25.708C14.4407 25.9107 14.4027 26.1007 14.3267 26.278C14.2507 26.449 14.1493 26.5978 14.0227 26.7245C13.896 26.8512 13.744 26.9493 13.5667 27.019C13.3893 27.095 13.2025 27.133 13.0062 27.133C12.8035 27.133 12.6135 27.095 12.4362 27.019C12.2588 26.9493 12.1037 26.8512 11.9707 26.7245C11.844 26.5978 11.7427 26.449 11.6667 26.278C11.597 26.1007 11.5622 25.9107 11.5622 25.708Z"
      fill="#DD4242"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 33C19.6274 33 25 27.6274 25 21C25 14.3726 19.6274 9 13 9C6.37258 9 1 14.3726 1 21C1 27.6274 6.37258 33 13 33Z"
      stroke={fillColor}
      strokeWidth="2"
    />
  </svg>
);

export default WarningIcon;
