export enum WalletId {
  WEB3 = 'WEB3',
  STATUS = 'STATUS',
  METAMASK = 'METAMASK',
  TRUST = 'TRUST',
  FRAME = 'FRAME',
  COINBASE = 'COINBASE',
  LEDGER_NANO_S = 'LEDGER_NANO_S',
  TREZOR = 'TREZOR',
  VIEW_ONLY = 'VIEW_ONLY',
  WALLETCONNECT = 'WALLETCONNECT',

  LEDGER_NANO_S_NEW = 'LEDGER_NANO_S_NEW',
  TREZOR_NEW = 'TREZOR_NEW',
  GRIDPLUS = 'GRIDPLUS'
}
