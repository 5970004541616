import { TUuid } from '@types';

export const ETHUUID = '356a192b-7913-504c-9457-4d18c28d46e6';
export const UBQUUID = 'fe5dbbce-a5ce-5e29-88b8-c69bcfdfde89';
export const DAIUUID = 'e1f698bf-cb85-5405-b563-14774af14bf1';
export const BATUUID = 'e34bb9ff-47be-503b-956b-30d4b3f3507b';
export const ETCUUID = '6c1e671f-9af5-546d-9c1a-52067bdf0e53';
export const USDTUUID = '00ff8c75-22ce-5e15-b06a-770154d7e333';
export const ZRXUUID = '36a94c95-ec51-5e0f-b254-415e31d1c3a7';
export const REPV1UUID = 'd017a1e8-bdd3-5c32-8866-da258f75b0e9' as TUuid;
export const REPV2UUID = '3dcbc269-fa47-5707-b180-4cb0ba6e5cdf' as TUuid;
export const LENDUUID = '1c77b322-a88c-57cc-b956-78c2bc17c360' as TUuid;
export const ANTv1UUID = '7f4d2f19-9a76-5930-b63b-ac9b0afe6201' as TUuid;

export const XDAIUUID = '310b86e0-b62b-5285-a2fc-91c7be5380a9' as TUuid;
export const USDCPolygonUUID = '4eaa10d0-3e33-5d58-9191-c5eb4c8ec1be' as TUuid;

export const GOLEMV1UUID = '59a6f871-34bb-5914-86cc-b5f1307bb3b9' as TUuid;
export const GOLEMV2UUID = '12470ffd-9ed1-5899-80b8-b1d30b1d1194' as TUuid;

export const EMPTYUUID = '00000000-0000-0000-0000-000000000000';

export const MOONPAY_ASSET_UUIDS = [
  ETHUUID,
  DAIUUID,
  BATUUID,
  USDTUUID,
  ZRXUUID,
  '41dc838d-355f-5e04-9763-4ab7a36cd70c', // BNB
  '685f1c7f-2d31-56c2-ae80-9cd433aeb0e1' // TUSD
];

export const AAVE_TOKEN_UUIDS = [
  'b8725c43-0f80-59c1-a9ea-ea6549be9ccd',
  'fc0b7870-9acb-561d-a5cf-a58f1d302251',
  'd2c77af8-b48e-5c61-ba87-83af832634a8',
  'f0a63788-507b-560d-b781-4ab471e7cf77',
  '79fc04fd-20a8-5563-8238-8834dbeb5ae8',
  '4001ed5d-8dbc-5b8b-9e46-f6c8d8463182',
  '929b4437-0fae-5a47-a9d6-7cae8d620051',
  'b0d7c33f-e0e9-5b89-9009-4bbb6e67e42d',
  '44646032-11ea-526a-a3d4-bf446c0c0ac3',
  '74f1c143-dcd9-5979-98d8-64c0657a38bc',
  '1459b7b7-06e0-5125-958a-ebfcccaf3444',
  '2dc7f25d-a3c9-5938-ad97-6a31fa52cbe3',
  '09a9a133-e7b2-57bd-b1bc-158e2436532a',
  '9454eb02-dda1-53d1-85f0-f6faa6c89267'
];

export const COMPOUND_TOKEN_UUIDS = [
  'a9cc6884-14bd-53b6-abcd-f9b56b60463d',
  '350c0d6f-a0eb-504e-9a8c-43a4d5966fc3',
  '4a21c8bc-3df3-5d61-b9c4-c6c2f2296914',
  'ce2fd294-87a4-55e5-a80b-192227cd4b2f',
  'e35a1b81-b775-5b8b-9744-ef997b4f677c',
  '4f96a9e6-bf30-54d0-90c0-3d6e7d7042f2',
  '7749b621-c2e8-553b-83c7-f73d86a38eb7',
  '282fd2da-f437-5f53-a167-ca7f7e91c57f'
];

export const FULCRUM_TOKEN_UUIDS = [
  '3c348242-f1b8-5372-8b2a-5a862d7205c4',
  '73e9f7c4-c101-55d3-a075-315e659a6c0c',
  'fb4c309c-0426-5dbc-8dc3-bf1569fea258',
  '8647f842-d4c7-5796-846a-35a7c3770d6f',
  'fb144c6d-8ef9-5601-a7e7-5bab174d79cf',
  'eda4ea2d-90d8-5dc7-a5dd-3ce28eb7e21b',
  '051a3dd5-d0ba-5142-8377-f42727e46c4b',
  'e553321d-3dbc-5ea3-9e57-2596abb73f7f',
  '14beb7e0-a88a-577e-9f80-602eae75634a',
  'e8cf87ef-af47-560b-b282-ca6a0c3c6880',
  '275d9ec7-0f15-540b-9861-ea2f9c02545a',
  '9615e1f0-ddee-56c7-b623-a73df3728757',
  '63d22210-7ecf-5068-aaeb-b871d08b127e',
  '236cc2b4-af48-5b06-8261-d9fbf2bb1101',
  '7b10d2f0-c0ad-58cf-ad94-4fd0cb408a3c',
  '10abd640-c896-5456-b22c-4d04e65dfc58',
  'de6958d7-6048-5201-80e6-4b413744d25e',
  '678d0732-9344-5452-9268-3bb8fb42383c',
  'c6e67b74-d51a-55e1-a011-fc47afdeb01e',
  '616ea56d-70e0-55df-ba32-9e578238ad7d',
  'f74549c2-3d62-51b9-bacf-52aa73efa5d2',
  '2dc52d9b-a03a-5ece-b10d-3a12e4051407',
  '0c501964-5a5c-5ad1-b342-5793fda1148c',
  '45fbea97-ea2f-5106-ae8f-ccb101e4ff54',
  '592bf38b-bd31-52b7-ab99-fd95d04af6b6',
  'b56bdbfb-9d87-5385-8775-269faaf9478c',
  '7f683785-9608-51cb-b00c-c0f434153b1d',
  'aa6fa1e7-6c1f-5045-946e-0dbbc0067f30',
  '4194a2b6-db5a-5564-b419-d38d582805df',
  '6a85692e-b35d-56b3-b392-57310db4781c',
  'f322b17b-9372-543b-a09f-9defdd2722d7',
  'a53ee769-5587-5c71-8b7e-6cefa62de671',
  '3e4f1585-5551-5ffe-b3cb-13393c7609e8',
  'ea3237b6-213b-537c-a13e-4c3eae797946',
  '1bed1cf2-c43e-5edb-b696-63377b9d7ac7',
  'd703ef47-9b59-572c-a3cb-d5cdfd567147',
  'b879de0a-4321-5338-a7ec-a3a589d49f88',
  'e74effc8-ed1a-546b-9c68-70164cc77578',
  '195e9f22-2146-56b7-9938-e4e1499831c3',
  '55b4ecf2-7356-50c2-b9e8-1a636d423728',
  '34a7f18a-adcb-5910-aafb-5fc8b4e2a5ed',
  'f2f74070-62d5-5a7b-87b1-88f75d3c227e',
  'f2d0fd8c-d301-5d07-8398-929334cb60f8',
  '656da7c9-65f2-521b-bdad-13818a16f955',
  'b7d58f2f-78b4-5dc5-926f-7ecf3742f0fb',
  '265a02cc-0258-516a-8e50-1d93600bff6c',
  '9285ae13-84bb-5033-a465-d0a4049c569b',
  'b1c1915e-6fdb-543c-a28f-dc4f752c56d9',
  'c4557f5a-a712-55ff-8d3d-099c2eca5f2d',
  '9bef8b43-4bf7-5705-9acb-a01745819dea',
  '5240ec8c-661a-5da5-909c-e0ae30fd3aec',
  'bb6dd3f6-b1b4-53e9-8918-936621f764fc',
  'a46176ac-a705-57c0-a041-4453bc5a3386',
  '033e5e18-f51b-51be-adc5-48acb8426357',
  '34190846-3621-5dc6-8f0e-13ae30f7f681',
  'af90520e-e72b-5899-bf51-acfff8865973',
  '3ac7ec59-8d53-505a-986c-befb874f8e68',
  'fbb06245-de55-5716-b94b-ba10a356e447',
  'f053179e-ab6c-54ba-acc7-2318985f8d8e',
  '775c0f5f-c7ea-505c-a3ee-e881ffa47910',
  '043a28a4-eea4-57f6-b204-2b895300fa3f',
  '74226aa2-5f0c-5ae0-993d-13a756d8d40d',
  'e07bdab9-127c-5ea0-9159-9759a999f1b0',
  'aa2ff97a-ed02-5225-a19a-cc00c34b0eed',
  'c182d889-17f9-5f84-9a08-94d07b38c01f',
  '1d2727b9-5a0c-54a7-b230-78b976e83094',
  'c1861d45-14ea-5b67-868f-b7af4cb6ba0a',
  'fec7dc29-fdb1-5fb3-9f3c-96ee15e78a1e',
  'b5fc2358-bb33-5617-b93f-79798a9338e8',
  'e9bae292-0cbb-5fc7-a60d-c364e76ae31e',
  '5d3187b8-bd69-5a22-a5c2-c729b2c1fc5f',
  'bfcfd7a3-6007-54eb-9298-0115cd54f420',
  '101b795c-bea8-5cd4-b592-a09f3fcc8940',
  '97a15cd8-bbab-5151-96bc-3e99755cff57',
  '7d3fe005-9089-56dd-bffa-ef98bc4129ea',
  '800078c9-c9b9-52ca-9976-28af77998662',
  'bf725ae5-aac3-578f-8e07-6d5cb5809557',
  '6e5fd7be-f8ed-5ecd-9ba0-380f5666208b',
  '5844fad9-f821-52a0-a60d-654c1f922b9d',
  '1958683a-41cb-5ded-9a90-92865e4fae56',
  'afe627a8-e5ef-559c-b261-9c836c7fd7e1',
  '1955f85d-a537-5664-9474-b4aab8cb8452',
  '0cbe3f71-c4f5-5fa9-8661-4409f86fc427',
  '389edb07-48cc-52a1-a7dd-342c3e54a2d8',
  '8d3ebbf3-60cd-5c0f-a40b-f27fd76254a6',
  '8468c1ab-1d71-5749-80c7-778546713ef6',
  '4fb6f529-9cb9-534f-9b21-eeaafedc6b79',
  '3c436ea1-aaff-5968-bd97-2cbd9bec48cb',
  'b73805b2-a2c6-5097-86c3-4e976af64785',
  '237b54c1-bd2c-5fa9-834f-89038f1ee190',
  '3ee5dfef-90e1-5aa4-a405-69d9ce63e66d',
  'f84c3cba-982d-58b0-aa4b-d9278350450d',
  'e3a84453-4f5c-5a2f-b639-19b9ffc36497',
  'af632d77-4c41-59b8-81fb-b11a25173e80',
  'f3bcbd5b-4e39-5000-a9c9-7ed3c21ed4bf',
  '07ea1788-bce3-5514-aa12-c3eb7fcb5cc4',
  '0621efc0-9d27-5177-bf6e-ade2e382d0c7',
  'dfa810fb-bdf4-5569-aaf0-473c2cbb48db',
  '01354657-f8e2-5780-86eb-6a32335cd552',
  'd26d84ce-024e-552a-9708-c1010545c1b8',
  'c14bdaaa-68bc-549b-adb1-a5209c7deddb',
  'f183fa22-3f73-5a8d-b9f7-c24f4063b40a',
  '7479d04d-f141-56cc-804f-184b497be059',
  'a78ceaf1-020e-5793-9b55-314829092702',
  'e70f41df-2c6a-5e83-a9d1-03cd317af1a5',
  'c878895a-f1d5-5b54-9517-067ec2e5d609',
  'bb7ba552-e0e0-5941-aa95-a72261973559'
];

export const SYNTHETIX_TOKEN_UUIDS = [
  'ee815921-f992-53bb-a0b9-5d31a6e62ee3',
  'e0e6a471-67e2-5221-930c-d8212157843f',
  '19d12b85-eb2f-512a-9313-5ed8e8b35630',
  '6c52f4a7-6b8f-557c-aeb8-d064c5ea2c03',
  '98b0e231-e14c-55fb-9fb7-e5bc89eb82bb',
  '9282006c-f73a-5eb5-9e72-9ac48385b884',
  'af2e9f01-f9c2-50f3-97bd-c748282ed3f4',
  '03fff89e-06f4-5df6-a7b5-77fde1387d6a',
  '8b88917b-1bf8-5350-ad04-129764c77fe5',
  'a7fc52e0-1765-5961-a997-be43200b1e1d',
  'e72c786a-95b1-5f27-a992-edc1a99f68ad',
  'd0f5269a-78c6-5734-a6a7-0c9b46107582',
  '19c48978-796d-55c6-a0e2-9999d1592728',
  '216fd189-889b-572f-96a6-4a26c2a3d12f',
  '4b857dcb-79a7-5334-b99d-4fb7d8ba0eb6',
  'eb4b7301-9257-542b-b201-cf942c1a603f',
  '31e5c50a-b3e1-57ab-a73a-0a2d78d14bd7',
  '444b172c-f801-5abb-9360-9dab2b74374b',
  'fdf39cb1-75c8-5092-9e56-0b5534034ef8',
  '3ee67d85-adb4-58b5-abfe-6a3b92943b4a',
  '3d410eca-64c7-5d38-8cca-d8b912d8c037',
  '6c7a1a4b-8987-57bc-a7ce-e763a6541839',
  '2c58991f-7486-5fd8-8593-888431c3ec47',
  '03e8ce7e-cb14-56ac-b019-52517d2239f9',
  '7216a675-13bc-50f3-951c-f94ca9ffe446',
  '3b4ad8b7-e09d-54f8-b4a2-05660280c6fb',
  '83e3a7ac-48b3-5e6f-b449-c5804987e476',
  'f658f002-8d7d-5107-839d-0bbf251b7936',
  '43761c63-7440-55ac-b20c-8f2496bbad56',
  '0ebf354c-3c09-5832-b62e-f3c95563d3a1',
  '1a70e986-461c-5cf7-9fad-a9e9b4386410',
  '633e7950-1971-529e-aa1c-fb4c8a611065'
];

export const UNISWAP_EXCHANGE_TOKEN_UUIDS = [
  '870be09a-55f5-56de-be20-9937050f2572',
  '50b83702-2652-5e12-8585-cd8014641b74',
  '528a02b4-c426-576c-9326-19939a3b2714',
  'a2c4fca6-ec18-55e2-96d8-fd8c8e9ae813',
  '0cbb8ae5-f084-5bc8-9d95-9efaf7e36dba',
  '59c11c4d-e32c-55a2-b4c7-1f5ca5b4f0ba',
  '73165fb5-4b61-59ed-82a7-baf8420c7bda',
  '0fd4fd95-c227-5ad7-b757-e3936263f466',
  '4ce970a1-2f8d-5d83-9c6f-532d568ec254',
  '1391f2f1-c924-55b4-83e8-b7af95c40c3d',
  'fb27dbbc-fdfe-5c68-9db9-be4371ee2543',
  'cf3aa546-dad1-51e8-ba3f-59934e6af29d',
  '70fe83de-5c6d-5f96-9d90-0889a7559e40',
  'f34348a9-47a3-5d0f-8623-e093ae5beba2',
  '459a37b4-ad23-5c2b-8030-031eeff78453',
  '2b7a4d65-9c40-5c21-96eb-f7d380a4dc87',
  'd08f92cc-4121-5351-a43e-8b441b3ebe18',
  '433ace33-756b-508e-9e52-b8b9abe39752',
  '180a5aee-0bc3-5737-9127-e9306446fd2f',
  '8cd31622-b42d-5e6f-8cbe-bc637efa912d',
  '609a348b-133a-50d3-a497-474137add6da',
  '9820d87f-14c5-5d85-9e6e-a9d0364fe231',
  '21e70510-5492-512b-95e1-72348b4a1a6d',
  'ad4988d0-ade1-5d4d-8143-c9dde2ed140d',
  'c90bcfa5-9b23-5add-bfa6-98cc0919eda5',
  'f0933b0f-5410-5338-8979-df335beb3b62',
  'f2d08ecc-d32c-5fae-8002-c477a47d632d',
  '74c77aac-bc10-55d3-9462-89644149b819',
  'ef9be964-a40a-5000-9c72-43ddaebcec2f',
  '7f1a87d4-7f88-5fcc-a9cc-db9fa947cf02',
  '021ce881-6817-55b1-a92c-ee824b5646b5',
  '4eb699dc-5dce-502d-9a23-194a58aa1450',
  '72039d77-81a0-5f22-bb92-548923298b10',
  '106732d8-b1ab-5515-816b-b165ed129dd3',
  '97a7bed6-0d39-57bf-a5ac-17c7adfa709b',
  'e729efdf-fab2-518e-b07f-0cff165cf304',
  '95b8e380-2c43-5a0a-ac3a-39241476c959',
  '1741b04b-4e9d-54c4-8c46-f17ce246d6ee',
  'b4692e52-6de2-5210-bc0e-1d4d77c11525',
  '5468e6f2-c8cc-5e78-8dd1-a7f6751112b5',
  'e39fd4ae-e817-51a9-a712-b821c93f6a63',
  '03d27066-3dd2-5f4b-abd4-6284d842fbbd',
  'ed8c1b4d-bc04-54c8-bc28-70da09cc3f88',
  'b456c951-f251-5494-886e-91c9f3461c71',
  '7561fc7f-621e-5666-b423-5ad4b383c95a',
  '8b89272d-341b-5dd9-ae49-a5f456134199',
  '44fabad2-d9da-50b3-b46b-e37466fd9751',
  'dcb18292-ea7d-5c54-b3fe-2c996f07e2c6',
  '83ee102e-0b80-53bf-998e-7f4a7f77e481',
  '8fca9c14-a87a-5ba8-a368-9029a0975f03',
  'ad0efbaa-bd94-5427-baff-c83b5e8319a2',
  'ce337576-e033-5be4-86ee-bd7ed750ddff',
  'f3815391-fc39-5a35-8c38-a07edca3faca',
  '5b6de87e-32af-51db-af21-d4a31b2fa6e2',
  '283aa7a9-4251-51dd-9aa9-c5c3f3fd77f2',
  'ade64d83-fd92-58ab-80ab-df336d4b290f',
  'f32b90a6-4325-5c19-9652-86472a0afc7c',
  'f7a4cf7a-6d6c-5ab5-b42a-ff173ee8a5d7',
  '718113cb-d087-528e-8092-7538dfacb9fe',
  '2220121c-1a7f-5342-abe8-5ed5b32f83ab',
  'f05be60f-c80f-5603-bedb-6d0a18d558c9',
  '73026704-b476-5cd9-ba73-49d6646ecf9e',
  '79a2ad75-3ead-5f2f-8e77-9801b81180a4',
  '7b299a00-1792-5201-a68f-5806ddf9bc13',
  '4dd376ed-f4af-56ea-86d9-c92454f024d6',
  'eaa1f49e-1e96-5344-b89b-ecb87f6b2d9d',
  '0cfef5bc-7370-5a17-b45e-d08037c856e0',
  '227adde0-9df8-55e5-8445-10c23fd595d6',
  'ca27272a-891e-577d-ae75-f8efe4d55231',
  '0039e50a-bab5-52fc-a48f-43f36410a87a',
  '26f97811-015b-50f9-be86-de591b8ff31c',
  '8e55d260-a85d-53c8-aa34-61a8cfc078df',
  'eaac5c67-473f-5154-98ca-c431340dc52c',
  '7c4ff905-8d4d-5832-898e-ac7d0965aaf6',
  '2e5729f2-d6cb-5e38-9c65-e96a9c5cca07',
  'f9086e7d-c815-58ca-8acf-30a2f2e65f08',
  'f3df8c39-1633-558d-8a17-02f93a1b466b',
  'ebad2b96-e8ff-5a3c-b68d-5ef21963d402',
  '5faceaf1-e3f7-57b7-80f6-eeab68e09c6d',
  '40aa5b7d-6a6e-548d-acff-82f3e0b29ab1',
  '7261743f-a97f-5bf8-b8c2-b4234ea91fe6',
  '990c2a73-781c-5412-ae55-702b23c6f5fd',
  'e0c874b0-ecb5-5ebb-979a-8cf26a7c007f',
  '1d94084b-5140-55b3-86b2-456291d1aab3',
  'dfe672db-4da0-573b-91c3-5a2e2b35d041',
  '6a8057fe-cb66-5195-8f0b-51677d820090',
  'ff235e5f-d628-5b00-b44a-1cc67cd72d37',
  'f140f96e-5aa7-548a-b47a-087469194da1',
  '43b7e3fb-86ea-595e-b3df-cda91181881d',
  '89167425-3f05-504e-88c6-2006273fcf7a',
  '2d616333-8e53-5703-9a82-f992263a671c',
  '6de1cff6-671e-58de-966f-8f8d2fd711fd',
  'a020c62d-151f-53f8-9cd2-14b0aafc1a06',
  '2a564380-964a-5f5a-8ca8-17d5d37d4b2c'
];

// These are the top tokens from etherscan's top tokens lists for market cap, holders, and market volume.
export const USEFUL_TOKENS = [
  '0765f5fa-f31e-5aa9-9122-c20b90d686f3',
  '00ff8c75-22ce-5e15-b06a-770154d7e333',
  'f383cc01-4942-5d0e-8442-3dbbc8cc8836',
  'c7da0cdc-82b7-51af-9bed-abe9559b6fea',
  '3ebda70e-d4a9-5a20-81a1-32f1109d3121',
  'c701b7d6-7506-5431-981a-333c47099b86',
  'b4ebfad5-090f-534e-aa26-e77035adc0fc',
  '4e7e2121-1f46-528b-bfe2-1fb1e2b512fb',
  'e34bb9ff-47be-503b-956b-30d4b3f3507b',
  '19efca0b-42c2-5711-bdd1-44a78a65d1b9',
  '41dc838d-355f-5e04-9763-4ab7a36cd70c',
  'f0935aad-cb4c-5f72-bc59-79879d718c87',
  'c0ae47d5-f020-5ea8-9886-522d3af70845',
  '2ed3ebcd-2d4f-52f7-b12b-54360ed49b55',
  '224ce04a-abc7-58fd-9f21-1e51881ce0c0',
  '53306efd-fb16-5e9a-b6d0-fc0e9713da40',
  'bdb47161-b46d-5f39-8e4e-1ab9dff33bde',
  '1c77b322-a88c-57cc-b956-78c2bc17c360',
  '72a513e4-100e-559f-9ce9-f918b8490516',
  'e1f698bf-cb85-5405-b563-14774af14bf1',
  '36a94c95-ec51-5e0f-b254-415e31d1c3a7',
  '84500917-7291-554e-8a56-7dd6a450e474',
  '305cabdb-546b-5a6a-92f5-f74038cf84b1',
  'ff3b1c13-af59-569b-903b-3b7c7fed95a8',
  'ef495648-b4c2-59a6-b1fe-3f74233d2a18',
  '13c0661d-68f1-5b8f-8ff8-410bb67c5f06',
  '60ef3be7-23f7-5018-b687-55dc0ddbfc1e',
  'c3df898a-3a25-561f-bdc1-c03ed733d7c0',
  '307b8424-6f00-5b71-8fc9-85d74abf54df',
  '500d9d71-cf78-5ccf-b72d-1bb554003cd7',
  'dc078640-511a-58a3-afc6-93a938473a4b',
  '0284bf17-367a-5648-833d-4219fe539141',
  'd69f3e38-de6b-5c27-be7f-a9df4581043c',
  'afefa1f0-e7a8-56c5-a83d-d0af38b48ff3',
  '59a6f871-34bb-5914-86cc-b5f1307bb3b9',
  'b367e1f3-ed04-5296-96e7-b490bd9e6c11',
  'cbb8d161-639f-5c6f-a180-ee37d76f1a07',
  '0a789985-67fc-5334-8ff9-4db94a0873a0',
  '0194aacc-2db6-5948-9a18-4e6bff90b02f',
  'c90311b2-90b2-5bf4-8d93-e06bd7cfb666',
  '99839180-6b19-5635-8ca2-c6bcff3b1599',
  '4688b480-155f-5f47-bc74-98ecd98da9ac',
  '412a623a-e940-55d0-a30c-01382f16ec97',
  'af736d99-639b-5cc7-b3b4-d7fe1e0a0a1f',
  '8138f106-9d3a-5b37-9e0e-ad48e9ee2109',
  '14c12705-cf16-5628-b888-929b8759b166',
  '51c80f7c-3a5f-5f21-9347-16380856e145',
  '4c4b621b-cbb9-5c72-8adc-a12ec9a8ccd7',
  'dd12ff2b-54af-546c-ad25-24339d73ba45',
  '574e6005-d39e-5c93-8855-c0f1e831e2fe',
  'b79cdc9b-9a97-5413-9a0b-bee09c086c78',
  '8a2577e2-7ab5-517a-9c10-034cecd3b1bd',
  '09f77ba8-191e-5aa2-ac95-5a5ff27591b0',
  '2dc6d39c-db25-555f-b3e2-768a870ae887',
  '6c1747fa-b66f-5841-ad86-fbc5279ab5db',
  'f314c356-4103-55a7-b5bb-31d912436300',
  '6c67c161-9c44-5ba7-85ec-7d91ac2c5e80',
  '0454fc63-bf6f-5476-b65a-f0b768170813',
  '80979028-926a-5991-868f-47e30c4feb2f',
  '69564c2d-5734-5a0e-b4f2-802080ae633c',
  '98a640a9-c220-59a3-b706-8c282de38363',
  '2fdbfe5d-5e3a-5fe0-bcaa-42810867d1e6',
  'ec9a8d15-6545-5ac5-a73c-e4763e0b239f',
  '011bc7fb-a0b6-5a9e-bd79-20de769d79c7',
  '8076742a-b231-548d-a534-80829c1e0221',
  '16ccb62e-a922-54db-8176-ba2d50cd8de2',
  'e7c922dd-eb6c-5bf7-b936-acec002eb290',
  'f7aebebd-20e6-5715-bbef-82676bf51a39',
  '7bd0761b-df34-5f0d-ac6e-403c93c5bebd',
  '41db5d55-b206-597e-bbe5-bca644d218d4',
  '44109d77-7f64-56c1-a0f7-42407ca9d7fd',
  'b3aed520-c84f-566c-9431-5263a2d40460',
  '18bdf79a-320f-5c29-b674-78756c8d3366',
  '5c920f9e-453f-59dc-a2b6-ae40665af481',
  '5d981e4a-ce39-5a95-accd-977402a89553',
  'ebc2c6b4-0e52-5a81-8255-4a0671cbccaa',
  '346ea204-df81-5e28-b8cd-52b061915fc4',
  '6c25f440-8ee5-56ac-8daa-1bd697772e4a',
  '483aa4c7-cc7a-56ee-bed3-5d007c7c1ead',
  '7c46a85a-75fb-5d1d-8c6b-81513b60b414',
  'cf9bde6e-e70e-59d9-ad7a-6e597e23a75c',
  '82133426-fcbf-592c-849c-1a7da90caec4',
  'ffc91b27-cf30-5a8f-85b5-122366c12a13',
  'f833640b-2938-5ef8-b051-ff7dc1ba12dc',
  '6722dd73-c8b2-585a-8f39-37e164f2d31d',
  'ddcc56f5-53e2-5a60-9eeb-d500c5ceb803',
  '2e70b225-92b1-5abe-b6bf-277a1332def2',
  '2db13e70-29b3-5fb4-bebe-1d97e0900851',
  '378e1cbd-6541-575b-a826-2799dd5129c2',
  '76ac7671-f48e-58c3-8fc0-485036f8382d',
  '89964402-7acd-5e98-811d-640846f557f5',
  'b9b550a6-8cb4-58e8-bdd8-3353d318adfe',
  '9002a714-6f71-5ab2-a398-3df87b2c9109',
  '8b00a088-9496-5e8e-915b-8cee9958aee2',
  '68a387f1-6a2b-5704-a2a6-a20eaff6fa3e',
  'ca05380d-9596-57c1-972f-a623c163e383',
  'd3c4f479-2b43-54f4-bd76-1ce454218a1d',
  '416e3514-aaf2-57f6-957b-0033b54fa043',
  '711d1af4-cefc-5f03-8057-0931c75384f7',
  'b75314e4-459a-58a8-a9ba-8144e77cf5be',
  'c95e0972-743f-5b17-b9ed-161cae6645f5',
  '40de5103-98a5-5dc2-b846-1840475f6d86',
  'c1e3a254-9c2d-5b19-85ce-d956a3e58ebc',
  '2294c9d3-ada3-50ac-9ed4-3084cf4bf9c7',
  '64053e3b-8762-5102-a361-7a5bfd1940a4',
  '3e654fac-32c4-5e4a-b4c4-186eeb0233c1',
  '0e99ca80-7edf-5b74-a2f5-03d37eed30bf',
  '41889207-c969-5449-90a8-5d6603a7eda2',
  '1d71b13d-12d6-50d2-922b-022966bc433a',
  '948e3baf-962e-509c-a269-3609ca1c843e',
  '5611d328-30e9-5319-8c59-7abc6c462b2b',
  'c9394e05-08fb-5710-817b-3199d2cfbefb',
  '10e5524e-b638-5925-961d-0f79273357bb',
  'ed9607ca-eba8-55a4-bff8-10a9cc3d9d26',
  'e9594b29-1981-5f94-b98c-8f8bc8a832a5',
  '99de3b1f-c899-553e-ba02-0558bdb1f046',
  'd1fa3cd0-c08c-51ed-aaf8-f07a4b80dd06',
  'b1b1a7e0-85d0-5a2d-84eb-c1224ffe07f5',
  '563bc456-acb5-519a-b037-a38e033f09b8',
  'e5745e08-d50a-583f-b54b-177109b133a7',
  '0fa076cc-bfca-5efb-a99c-c1b4d518ff5d',
  'e9d2ff38-5815-5e21-95a1-4ad8d5e408aa',
  '6fb748bf-d2f4-516e-b5b7-29b588f1851d',
  '688f099f-83a7-5102-b16e-7f97fe22b023',
  'f4d6e8d4-74ce-509b-87bb-2dc1ea1f1792',
  '9f563cf7-f883-5459-845e-5021a2f58629',
  '710be2c3-3d37-5f00-9f9c-367e3ba3aa05',
  '09f2dfa4-38c7-5a7d-961e-4e64580b8649',
  '4451da87-f76d-5e89-b88b-e27230b069a0',
  'c5934e2b-9786-5e49-a7a8-b80a94c92a78',
  'f3b06f82-5140-5faa-8487-3f206fac65a7',
  'a6abdfa6-17ad-5a26-9c7f-12c5774eea84',
  'd4ad5d17-8c54-5c97-8dce-3990925db2b3',
  'd4a935c9-92ba-5e8d-b66f-c19e704b0c8a',
  '4ea90082-23fe-55e5-8b4a-a23cda228e65',
  'd0f5269a-78c6-5734-a6a7-0c9b46107582',
  'd3467e44-61ad-55b6-85ae-008e893c4e14',
  '2c650f69-9793-53c6-a48c-2003b6e4b095',
  '8e1b3751-7b15-50d0-a181-5f68708fe4aa',
  '30497635-d6da-53e1-be28-7e291e458c4f',
  '8aeb3831-7f63-5943-9b9b-a3688cc47515',
  '07b60b48-978e-5390-827b-f105b2e37df4',
  'd64c6a6f-4e7f-509b-9bee-f2a7d92727d5',
  '42820809-fcea-5d5f-9ee2-53954c43c401',
  'e203c06c-15a6-5931-bf78-6b123aeb1b0a',
  '59eb5fb9-ce3f-55c1-ab02-934093c96540',
  '0c7a799d-c135-5ce5-ae37-b5d960510a18',
  '6bce4743-059f-53de-a7c5-c74ea833ecd8',
  'e85aee53-01a7-576a-bbd4-1b70e25509d9',
  'd5afdcfc-50de-5fb9-bda8-510b52253ef2',
  '7f391173-7933-5bc8-a1a0-f50e23f68ab4',
  '56c278c7-56a6-5324-8ef9-490726f5756f',
  'da3f67df-da91-5ab4-a94b-f08fbe8d7670',
  'ad0b8283-0f45-5604-8478-21ab05a2016a',
  'ff38f676-c72f-5592-9b39-39401f60ff77',
  'abfa35c0-e42c-572c-b8d5-bf2c51ef05fb',
  '3d64e542-0c5d-5803-a3ac-5890185eeb03',
  'fbf02b73-5b46-54f9-8841-616ad6cd228e',
  'b624cbe8-d9a2-53fa-84ba-00589010c840',
  '5e3cded5-1ef3-5229-9564-96d7794847ff',
  '3ff6638d-944c-5763-a94c-dc615ba1b76e',
  '89e35266-631d-5aad-a174-2f192741f98c',
  '91a9d1fd-fc88-5d55-81db-38637d9ade7a',
  'e3615a0b-bb95-5895-821e-792110bcc07d',
  '3e82a7f0-222a-5cc8-b1f2-bc4bd8e7e62d',
  '47df7dbf-68e6-50b5-9c9d-d0b18025ea8d',
  '8c2be557-2d1a-542a-9e78-e9c0ababd401',
  '5f3d1412-197d-571e-ae71-b8f107599089',
  '624f1ca6-008f-5f81-8e84-8f39726329e1',
  'b1e7f0d3-990f-5562-9662-dc13a9ffb54b',
  'd79d0f9a-791c-5ca0-9a92-0d309a31f26a',
  '1d89d5bc-bf19-5f82-84f5-3b9db5dee80c',
  'fd38e53c-3f8f-5ed1-be78-15e2fbd7df3c',
  'c95cb73a-55f6-5554-a7b7-f8af5d028031',
  '77545d72-6e82-5169-a2b4-c1f23ee212aa',
  '98ff154d-c9a4-588b-a813-8003adc4a43c',
  '45d4fd4c-b547-59ea-a3a6-44649cd77f82',
  '818c214f-e39f-534d-9b7e-7b452768f3f7',
  'f7f1de8b-54cc-52e9-b748-f5046ecea895',
  '0ac78fba-5efa-5121-a99e-0c5625e55f3c',
  '00eff59a-3d09-5856-80e5-b51ddfedc360',
  'a6191380-522b-5759-bade-99c8b66f896b',
  '451fbc3f-eb36-5934-897f-98fa425c806f',
  '8537b665-0157-5a80-a028-2dc71eecd1d6',
  '91f185fa-c460-5b83-9ea0-ac456d156bc7',
  'edcb7571-8d8e-5aeb-9a58-1f089121283d',
  '084130da-e48e-554e-b16b-3980c19559cd',
  'b39b218d-e57a-50bc-adba-716ab59e9608',
  '908a5a98-6487-52f7-81dd-68edb350b4f8',
  '61743c08-5347-572a-911f-cdb52c62b9b1',
  'e5ad70d8-db9d-5661-91d6-e54f8a2e5ca0',
  '9c25835c-3c9a-5319-bd8a-f469cd2378ec',
  '1d65e181-1121-5157-9c84-6f28ac4ce07e',
  'cf6a2f3f-bbf4-5f50-9fa6-f246b91649cd',
  '8d0d2e0e-d592-5ad1-a194-6c497ed44c1b',
  'a4aeb323-2d6b-5456-a6ca-5b900b2e433c',
  'ec5b4682-0b96-5a0b-b760-f22e71c794c7',
  'd7010b42-4c73-5677-8b82-36fc279aec6e',
  'e86f88a9-3533-55ad-91a1-1ae44408ea6c',
  '8af0b8ee-1d32-5514-903c-09973c4bc97f',
  'c284a039-7fb7-5ff8-9d76-22e1321b92f6',
  '778541d2-65e6-5c57-b083-aa3129eb91c8',
  '070ce3cb-5a08-5c45-9f64-8fe1bea6e852',
  '25c88136-cf18-59ed-9599-ea1fac2ecdb0',
  'e4194ff1-6c36-5f61-bf3e-e4359ae92af9',
  '68f6aa06-cf9d-5614-a75b-e35a8b9c9024',
  '6d83ba56-5844-525b-98f6-e1cb3001317f',
  'a6e1b8fd-8a45-543f-930b-56842df36e95',
  '784b6ad3-87d5-56a6-a428-9e563a413fce',
  '22e030b9-7476-54ed-a6e3-3eb939ea0c0b',
  '64942305-fcd6-5ce3-aa8e-53fb8bc6ccaf',
  '3d10c6ef-249c-525a-8725-d7e7b7495948',
  '9545ac04-a3f3-541f-92bd-bd5f3823a766',
  'b7b4cc77-fb11-56d7-86e1-cd21165b4370',
  '81f05362-692a-50ed-9974-3134005acaee',
  'b9ad3d78-3891-5686-92ba-ca0150be8a14',
  'e35a1b81-b775-5b8b-9744-ef997b4f677c',
  '9fad8ea0-256e-55e7-9129-851b89c9caaa',
  'ccb6a953-6fa6-5170-b6d5-aa301e3cfc61',
  '24d884a7-dbf3-55fa-9d8c-4eaa83c8f49a',
  '4930405c-4f62-5664-bb65-f8b300cbf220',
  '97ae2210-28d0-5dec-a58b-5b90658fc3c1',
  'aae1b854-1ab4-5fd0-9f7d-6a2b3d32507e',
  'd83584dc-22c7-5b53-ae29-9dc81a8ef251',
  '5a7d7aa0-bf99-51c6-a10f-6864a331a2c1',
  'f5212133-7c4f-5047-8c23-ea2ede62f796',
  'aa6dcf82-4dc0-5eaa-8dc9-8598a24b434a',
  '489a483c-290b-56ac-b384-b683d6d2a648',
  'daf91fed-70b7-5bb7-9921-f58edc8834b6',
  '6f3dc51e-1769-5b6d-beb1-63f24d977ac7',
  'baac1cc3-b2d4-548d-961f-bae76b6bbeb4',
  'c159a267-2a75-509a-809a-d7587fc787b3',
  'f10e8e7a-36ed-5350-97e0-c94df65dc519',
  '04b41ce6-acdd-530e-9265-d446727aa8cb',
  '50345c7a-eb5b-5105-bfac-4d722c5dacea',
  '10b38465-640c-509a-b047-8d9671a245ae',
  '988add8d-eb6d-5bce-be28-fe73c3af72ed',
  'd3230312-cacb-52e6-a849-8bbf6e8142dd',
  '0486cb78-9392-5ff0-a790-03843d79b073',
  'c9a85d0b-5f75-5fd7-9cd2-b94ba5caceef',
  '2db84be6-32ae-50e3-81b8-59694bef84a1',
  'f106faf5-59fc-5277-b9ad-6e2a7cddce9f',
  '685f1c7f-2d31-56c2-ae80-9cd433aeb0e1',
  'bb41cf65-8684-528d-8923-2ec68f49b2f0',
  '4971910f-508b-5282-bf41-7be8a847a277',
  'd24cb974-e368-5ec9-9269-5b50c923b230',
  '3512fbf5-2346-5deb-95a2-1bc4a09e717b',
  '45cc0791-d3e8-548b-b93f-56141e00f45e',
  'aae5349e-9f5a-59de-a54d-702518fd94ad',
  '6634f176-fd76-5227-a67d-23db41ee9fd5',
  '282fd2da-f437-5f53-a167-ca7f7e91c57f',
  '52f6407e-e3de-50f6-a26a-23702e0efc16',
  'fae2e672-c46b-5c80-b2cd-e218c8cc9e25',
  '3dcbc269-fa47-5707-b180-4cb0ba6e5cdf',
  'bebb34bf-3639-5b74-86f5-26ac66c7b47f',
  'f2f96a17-767e-5aaa-831a-00e5429162b7',
  'e02a1b4e-ab8b-5894-a49e-a36b74bd99ab',
  '5f82a198-c58b-5fce-8973-42c818ccb05f',
  '7f4d2f19-9a76-5930-b63b-ac9b0afe6201',
  'f94baa2a-b190-5362-857f-f899b818fd72',
  'da5819e1-1668-5b7c-af9c-2d43665a5116',
  '01afcee3-fafb-5260-a34c-786479a8199a',
  'c07fe952-47cc-591a-be8a-2313965b5d00',
  'cb9246a5-6a54-595d-b8b9-055da1b675a9',
  'bfc51e60-43db-53a2-8953-4002578e13d2',
  'f1ca8ca6-307f-5f04-bccb-5a2144551695',
  'd64c6a6f-4e7f-509b-9bee-f2a7d92727d5',
  '42820809-fcea-5d5f-9ee2-53954c43c401',
  'e203c06c-15a6-5931-bf78-6b123aeb1b0a',
  '59eb5fb9-ce3f-55c1-ab02-934093c96540',
  '0c7a799d-c135-5ce5-ae37-b5d960510a18',
  '6bce4743-059f-53de-a7c5-c74ea833ecd8',
  'e85aee53-01a7-576a-bbd4-1b70e25509d9',
  'd5afdcfc-50de-5fb9-bda8-510b52253ef2',
  '7f391173-7933-5bc8-a1a0-f50e23f68ab4',
  '56c278c7-56a6-5324-8ef9-490726f5756f',
  'da3f67df-da91-5ab4-a94b-f08fbe8d7670',
  '9002a714-6f71-5ab2-a398-3df87b2c9109',
  'ad0b8283-0f45-5604-8478-21ab05a2016a',
  'ff38f676-c72f-5592-9b39-39401f60ff77',
  'abfa35c0-e42c-572c-b8d5-bf2c51ef05fb',
  '3d64e542-0c5d-5803-a3ac-5890185eeb03',
  'fbf02b73-5b46-54f9-8841-616ad6cd228e',
  'b624cbe8-d9a2-53fa-84ba-00589010c840',
  '5e3cded5-1ef3-5229-9564-96d7794847ff',
  '3ff6638d-944c-5763-a94c-dc615ba1b76e',
  '89e35266-631d-5aad-a174-2f192741f98c',
  '91a9d1fd-fc88-5d55-81db-38637d9ade7a',
  'e3615a0b-bb95-5895-821e-792110bcc07d',
  '3e82a7f0-222a-5cc8-b1f2-bc4bd8e7e62d',
  '47df7dbf-68e6-50b5-9c9d-d0b18025ea8d',
  '8c2be557-2d1a-542a-9e78-e9c0ababd401',
  '5f3d1412-197d-571e-ae71-b8f107599089',
  '624f1ca6-008f-5f81-8e84-8f39726329e1',
  'b1e7f0d3-990f-5562-9662-dc13a9ffb54b',
  'd79d0f9a-791c-5ca0-9a92-0d309a31f26a',
  '1d89d5bc-bf19-5f82-84f5-3b9db5dee80c',
  'fd38e53c-3f8f-5ed1-be78-15e2fbd7df3c',
  'c95cb73a-55f6-5554-a7b7-f8af5d028031',
  '77545d72-6e82-5169-a2b4-c1f23ee212aa',
  '98ff154d-c9a4-588b-a813-8003adc4a43c',
  '45d4fd4c-b547-59ea-a3a6-44649cd77f82',
  '818c214f-e39f-534d-9b7e-7b452768f3f7',
  'f7f1de8b-54cc-52e9-b748-f5046ecea895',
  '0ac78fba-5efa-5121-a99e-0c5625e55f3c',
  '00eff59a-3d09-5856-80e5-b51ddfedc360',
  'a6191380-522b-5759-bade-99c8b66f896b',
  '451fbc3f-eb36-5934-897f-98fa425c806f',
  '8537b665-0157-5a80-a028-2dc71eecd1d6',
  '76ac7671-f48e-58c3-8fc0-485036f8382d',
  '91f185fa-c460-5b83-9ea0-ac456d156bc7',
  'edcb7571-8d8e-5aeb-9a58-1f089121283d',
  '084130da-e48e-554e-b16b-3980c19559cd',
  'b39b218d-e57a-50bc-adba-716ab59e9608',
  '908a5a98-6487-52f7-81dd-68edb350b4f8',
  'd69f3e38-de6b-5c27-be7f-a9df4581043c',
  'c469f604-d610-5252-8f8b-580ca1837e58',
  '12470ffd-9ed1-5899-80b8-b1d30b1d1194',
  '281fa3af-8e6d-5f33-900c-33a480035475',
  'bcc71596-ea9d-5a90-add0-14e1717f22a1'
];

export const USEFUL_ASSETS = [...USEFUL_TOKENS, ...MOONPAY_ASSET_UUIDS];
