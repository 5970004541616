import { FC } from 'react';

interface WizardIconProps {
  size?: 'sm' | 'lg';
}

const WizardIcon: FC<WizardIconProps> = ({ size }) => (
  <svg
    width={(size || 'sm') === 'sm' ? '61' : '186'}
    height={(size || 'sm') === 'sm' ? '54' : '169'}
    viewBox="0 0 186 169"
    fill="none"
  >
    <path
      d="M49.7815 127.602C76.939 127.602 98.9545 105.587 98.9545 78.4294C98.9545 51.2719 76.939 29.2563 49.7815 29.2563C22.6239 29.2563 0.608398 51.2719 0.608398 78.4294C0.608398 105.587 22.6239 127.602 49.7815 127.602Z"
      fill="#78E1F2"
    />
    <path
      d="M92.0618 157.845C89.2452 158.941 83.4843 160.164 80.5466 161.099C75.5319 162.678 79.6122 169.112 88.7679 165.898C91.5576 164.917 95.6111 163.028 97.2783 161.388C98.4614 160.231 98.6025 156.521 92.0618 157.845Z"
      fill="white"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M128.04 155.346C130.655 156.422 136.067 157.41 138.883 158.707C144.308 161.235 140.167 166.895 130.541 163.292C127.684 162.23 125.688 161.739 123.731 159.85C122.044 158.243 121.419 153.813 128.04 155.346Z"
      fill="white"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M94.3648 138.488C94.1363 138.958 92.523 143.308 91.6222 145.755C91.2179 146.841 91.0042 147.99 90.9903 149.149C90.95 152.094 90.8827 157.801 90.95 158.681C91.1718 161.458 95.427 161.37 95.548 158.614L97.0336 148.632C97.0336 148.632 100.657 142.037 100.959 141.412C103.05 137.137 96.321 134.488 94.3648 138.488Z"
      fill="#FFD167"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M119.938 138.158L124.792 156.98C125.02 158.997 129.061 158.553 128.825 156.516L127.555 136.544L119.938 138.158Z"
      fill="#FFD167"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.9058 62.099C61.9058 58.0657 64.9241 55.1953 69.1389 54.8928C83.8337 53.8508 135.884 54.8928 156.528 55.6726C157.509 55.6924 158.477 55.9075 159.375 56.3054C160.272 56.7032 161.082 57.2759 161.756 57.9898C162.43 58.7038 162.955 59.5448 163.3 60.4639C163.645 61.3829 163.804 62.3615 163.768 63.3426V101.713H60.8975L61.9058 62.099Z"
      fill="#02BAEC"
    />
    <path
      d="M157.989 70.3069V68.3708C157.989 65.0097 155.253 62.4553 151.885 62.1864C132.276 60.6336 92.5884 60.3512 72.5629 61.4806C71.4806 61.4977 70.4472 61.9339 69.6799 62.6974C68.9127 63.4609 68.4714 64.4922 68.4489 65.5744V68.8414M61.9082 62.099C61.9082 58.0657 64.9265 55.1953 69.1413 54.8928C83.8361 53.8508 135.886 54.8928 156.53 55.6726C157.512 55.6924 158.48 55.9075 159.377 56.3054C160.275 56.7032 161.084 57.2759 161.758 57.9898C162.432 58.7038 162.957 59.5448 163.302 60.4639C163.648 61.3829 163.807 62.3615 163.77 63.3426V82.4741L61.9082 62.099Z"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.1"
      d="M68.4338 65.5728C68.4338 65.5728 130.218 65.519 157.006 65.4249C160.522 65.6266 163.365 66.8231 163.365 74.0495C163.365 74.0495 163.036 70.0162 152.986 70.2649C142.936 70.5136 69.1396 69.4246 69.1396 69.4246C59.7285 67.5559 60.6696 60.0808 70.1076 62.2991C68.0977 64.7998 68.4338 65.5728 68.4338 65.5728Z"
      fill="#163050"
    />
    <path
      d="M164.542 108.665C169.134 108.833 168.576 102.4 166.895 95.3012C166.434 93.523 165.514 91.8972 164.226 90.5865C162.939 89.2758 161.331 88.3262 159.561 87.8328L164.542 108.665Z"
      fill="#0088CC"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M174.458 85.5068C165.652 75.8738 153.162 70.6775 142.077 66.0123C121.574 57.381 111.262 46.0675 118.751 40.6628C140.564 38.6999 156.846 45.5364 172.018 59.3103C182.074 68.2575 182.236 83.9136 174.458 85.5068Z"
      fill="#163050"
    />
    <path
      d="M184.395 25.3465C183.649 31.6184 170.4 27.4573 167.96 23.666C161.775 30.9058 166.958 46.6358 167.422 49.8289C170.837 73.4844 111.432 51.1733 123.277 30.8655C132.204 15.5321 149.205 1.36165 160.921 1.87254C177.539 2.60526 175.946 17.1387 179.468 20.9233C182.238 23.8609 184.51 24.3718 184.395 25.3465Z"
      fill="#C8B1FF"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M170.139 60.8077C170.684 75.9932 114.728 56.7072 118.109 42.7922C119.01 39.0613 121.154 34.6179 123.258 30.8669C122.788 40.3587 161.777 58.5087 166.953 48.2036L167.074 48.0826C168.391 53.4671 169.89 58.2264 170.139 60.8077Z"
      fill="#FFD167"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M168.191 24.0116C166.537 22.3201 165.236 20.3159 164.366 18.1162"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M167.449 49.8243C162.743 44.2516 155.86 32.5482 163.772 18.0349C164.921 20.0108 166.307 21.8392 167.899 23.4799C162.246 30.0475 166.165 44.386 167.449 49.8243Z"
      fill="#163050"
    />
    <path
      d="M156.55 143.406C125.365 145.537 99.9553 144.966 69.1608 142.626C64.7712 142.297 62.3445 138.701 62.0487 135.38C59.7899 110.92 59.7494 86.3058 61.9277 61.8386C61.8936 62.8149 62.0548 63.7882 62.4016 64.7014C62.7485 65.6146 63.2742 66.4494 63.9478 67.1568C64.6215 67.8643 65.4295 68.4302 66.3247 68.8213C67.2198 69.2125 68.1841 69.421 69.1608 69.4347C98.9268 70.6313 127.725 70.8867 156.55 70.2145C158.477 70.2392 160.318 71.0219 161.673 72.3932C163.027 73.7644 163.788 75.614 163.79 77.5417C163.79 77.5417 164.092 121.545 163.79 136.207C163.702 140.112 160.899 143.144 156.55 143.406Z"
      fill="#02BAEC"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M155.149 139.898C156.397 139.898 157.594 139.402 158.477 138.519C159.359 137.637 159.855 136.44 159.855 135.192M159.855 78.9338C159.855 77.6776 159.362 76.4716 158.482 75.5752C157.602 74.6788 156.405 74.1638 155.149 74.1409L159.855 78.9338Z"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.3138 96.699C95.2644 96.6976 94.2322 96.965 93.3155 97.4757C92.3988 97.9865 91.6282 98.7234 91.0771 99.6164"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.3136 105.873C97.9842 105.873 99.3386 104.518 99.3386 102.848C99.3386 101.177 97.9842 99.8228 96.3136 99.8228C94.6429 99.8228 93.2886 101.177 93.2886 102.848C93.2886 104.518 94.6429 105.873 96.3136 105.873Z"
      fill="#1D314D"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95.4291 102.831C96.0528 102.831 96.5585 102.326 96.5585 101.702C96.5585 101.078 96.0528 100.573 95.4291 100.573C94.8054 100.573 94.2998 101.078 94.2998 101.702C94.2998 102.326 94.8054 102.831 95.4291 102.831Z"
      fill="white"
    />
    <path
      d="M127.958 99.9524C127.465 99.0269 126.744 98.2428 125.862 97.6745C124.981 97.1063 123.969 96.7727 122.923 96.7056"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M122.532 105.873C124.202 105.873 125.557 104.518 125.557 102.848C125.557 101.177 124.202 99.8228 122.532 99.8228C120.861 99.8228 119.507 101.177 119.507 102.848C119.507 104.518 120.861 105.873 122.532 105.873Z"
      fill="#1D314D"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M121.647 102.831C122.271 102.831 122.777 102.326 122.777 101.702C122.777 101.078 122.271 100.573 121.647 100.573C121.024 100.573 120.518 101.078 120.518 101.702C120.518 102.326 121.024 102.831 121.647 102.831Z"
      fill="white"
    />
    <path
      d="M121.284 112.569C121.284 112.724 121.25 119.567 115.799 122.652C114.451 123.4 112.968 123.87 111.436 124.037C111.248 124.037 111.059 124.084 110.865 124.098C109.515 124.224 108.154 124.138 106.831 123.842C99.8132 122.182 98.6704 114.431 98.6704 114.431L121.284 112.569Z"
      fill="#1D314D"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M116.416 119.683C116.271 120.689 116.065 121.686 115.798 122.667C114.45 123.415 112.967 123.885 111.435 124.052C111.247 124.052 111.059 124.099 110.864 124.113C109.514 124.239 108.153 124.153 106.831 123.857C107.635 122.176 108.181 120.384 108.451 118.54C108.602 117.481 109.168 116.525 110.024 115.884C110.88 115.242 111.956 114.967 113.015 115.118C114.074 115.27 115.029 115.836 115.671 116.692C116.313 117.548 116.588 118.624 116.437 119.683H116.416Z"
      fill="#57B9E6"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M112.155 120.415C112.095 121.617 111.869 122.805 111.482 123.944"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.1"
      d="M170.384 122.244C172.945 132.932 139.616 146.767 135.059 127.696C129.93 106.245 160.408 108.806 165.746 102.823L170.384 122.244Z"
      fill="#163050"
    />
    <path
      d="M164.794 128.834L154.677 131.664C141.421 135.361 136.299 114.455 149.669 110.725L159.753 107.908H159.793C165.036 106.442 168.223 100.803 166.912 95.3105L166.784 94.7861L171.913 116.25C173.224 121.736 170.038 127.375 164.794 128.834Z"
      fill="#02BAEC"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M160.348 119.658C158.694 112.74 148.691 115.503 150.359 122.447C152.026 129.391 162.008 126.602 160.348 119.658Z"
      fill="#FFD167"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M167.046 111.417L168.955 119.423"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M159.278 106.551L157.691 103.936C141.323 108.043 131.515 109.542 118.823 109.946L117.062 112.984L167.116 148.706L159.278 106.551Z"
      fill="#C8B1FF"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M170.321 145.831C161.185 154.967 147.969 160.015 147.969 160.015C147.969 160.015 130.102 159.215 120.65 153.522C115.199 137.261 116.987 112.987 116.987 112.987C129.087 113.04 143.203 110.909 159.337 106.479C158.866 106.842 168.539 122.136 170.321 145.831Z"
      fill="#C8B1FF"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M152.978 129.146L149.119 134.914L149.818 141.818L143.143 139.929L136.791 142.725L136.522 135.794L131.897 130.618L138.411 128.225L141.9 122.222L146.195 127.681L152.978 129.146Z"
      fill="#FFD167"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.0231 85.8669C50.9879 82.8957 54.6649 81.7462 55.7338 84.7039C59.4915 95.1234 80.969 152.491 81.2782 153.237C82.6764 156.484 77.4129 158.615 76.0147 155.086C75.7122 154.292 55.6464 96.2998 52.0231 85.8669Z"
      fill="#C8B1FF"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.8864 108.657C55.443 111.695 51.3223 117.012 51.3223 125.422C51.3223 142.086 74.1778 157.595 80.9538 145.817C83.7301 141.018 78.6346 133.368 74.2316 134.302C74.2316 134.302 75.0584 136.413 75.3542 137.105C76.6986 140.218 71.7645 142.369 70.4806 138.954C70.1042 137.959 69.0756 135.203 69.0756 135.203C66.3868 135.767 65.1028 138.752 65.1028 138.752C55.201 133.233 54.2599 119.869 61.3317 113.114L59.8864 108.657Z"
      fill="white"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.1"
      d="M45.3431 101.765C39.8847 101.476 39.8645 109.832 45.3431 110.094C50.8217 110.356 50.8284 102.054 45.3431 101.765Z"
      fill="#163050"
    />
    <path
      d="M43.996 102.687C41.5087 102.552 41.4953 106.357 43.996 106.485C46.4966 106.613 46.5033 102.815 43.996 102.687Z"
      fill="#FFD167"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.1"
      d="M58.8259 81.8146C64.4658 87.8108 73.0299 79.6702 67.3631 73.6538C61.6962 67.6374 53.1657 75.8049 58.8259 81.8146Z"
      fill="#163050"
    />
    <path
      d="M58.8983 78.444C62.6695 82.4773 68.3968 77.0121 64.6122 72.9923C60.8276 68.9724 55.1137 74.4241 58.8983 78.444Z"
      fill="#FFD167"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.7347 63.8582L38.1905 61.7407L33.4715 62.8767L33.8345 57.9561L31.0649 53.7076L35.8377 52.7867L38.8493 49.0222L41.4373 53.3782L46.0622 55.2941L42.8826 58.9106L42.7347 63.8582Z"
      fill="#FFD167"
      stroke="#163050"
      strokeWidth="2.96327"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WizardIcon;
